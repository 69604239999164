import React, { useState } from "react";
import { Outlet, useLocation, useOutlet } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { AnimatePresence, motion } from "framer-motion";

const AnimatedOutlet = () => {
    const o = useOutlet();
    const [outlet] = useState(o);

    return <>{outlet}</>;
};

export default function AppLayout() {
    const location = useLocation();
    return (
        <div>
            <Navbar />
            <AnimatePresence mode="wait">
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 1, x: 100 }}
                    animate={{ opacity: 2, x: 0 }}
                    exit={{ opacity: 1, x: -100 }}
                    transition={{ duration: 0.3 }}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <main className="p-0">
                                {/* <Breadcrumbs /> */}
                                <AnimatedOutlet />
                            </main>
                        </div>
                    </div>
                </motion.div>
            </AnimatePresence>
        </div>
    );
}
