export const Icon = ({ className, src, alt, height, width, color }) => {
    return (
        <>
            <img
                className={className}
                src={src}
                alt={alt}
                height={height}
                width={width}
                style={{ color: color }}
            />
        </>
    );
};
