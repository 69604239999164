import React, { useMemo } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import "./table.scss";

/**
 * Table component wrapped around `react-table`.
 * @param {{
 * data: any[],
 * columns: any[],
 * isSortable?: boolean,
 * isPaginated?: boolean,
 * pageSize?: boolean,
 * pageIndex?: number,
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function BasicTable({
    data,
    columns,
    isSortable = false,
    isPaginated = false,
    pageSize = 10,
    currentPage = 0,
}) {
    const tableColumns = useMemo(
        () =>
            columns.map((col) => ({
                ...col,
                Header: col.header,
            })),
        []
    );

    const pluginList = useMemo(() => {
        let list = [];

        if (isSortable) {
            list.push(useSortBy);
        }

        if (isPaginated) {
            list.push(usePagination);
        }

        return list;
    });

    const tableInstance = useTable(
        {
            columns: tableColumns,
            data: data,
            initialState: {
                pageSize: pageSize,
                pageIndex: currentPage,
            },
        },
        ...pluginList
    );

    const getHeaderProps = (tableInstance, column) => {
        if (isSortable) {
            return column.getHeaderProps(column.getSortByToggleProps());
        }

        return column.getHeaderProps();
    };

    const getStatusCellStyle = (status) => {
        switch (status) {
            case "Completed":
                return { color: "#01CB8E", backgroundColor: "#CCF4E8" };
            case "Failed":
                return { color: "#FF2F2F", backgroundColor: "#FFD0D0" };
            case "Pending":
                return { color: "#FFB302", backgroundColor: "#FFE885" };
            default:
                return {};
        }
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div>
            <div className="table-responsive">
                <table
                    className="table table-striped table-borderless table-sm shadow-sm"
                    {...getTableProps()}
                >
                    <thead className="table-header">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...getHeaderProps(
                                            tableInstance,
                                            column
                                        )}
                                    >
                                        {column.render("Header")}
                                        {isSortable && (
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? " ˅"
                                                        : " ˄"
                                                    : ""}
                                            </span>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {(isPaginated ? tableInstance.page : rows).map(
                            (row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    className="p-2"
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </div>
            {isPaginated && (
                <div className="m-5 d-flex justify-content-end mb-2 mb-md-0 me-3 table-pagination">
                    <nav>
                        <ul class="pagination">
                            <li
                                class={`m-1 page-item ${
                                    !tableInstance.canPreviousPage
                                        ? "disabled"
                                        : ""
                                }`}
                            >
                                <a
                                    class="page-navigator page-link"
                                    href="#"
                                    onClick={tableInstance.previousPage}
                                >
                                    <span className="m-1">{`<`}</span>
                                    Previous
                                </a>
                            </li>
                            {[...Array(tableInstance.pageCount)].map((_, p) => (
                                <li
                                    class={`m-1 page-item ${
                                        p === tableInstance.state.pageIndex
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <a
                                        class="page-link"
                                        href="#"
                                        onClick={() =>
                                            tableInstance.gotoPage(p)
                                        }
                                    >
                                        {p + 1}
                                    </a>
                                </li>
                            ))}
                            <li
                                class={`m-1 page-item ${
                                    !tableInstance.canNextPage ? "disabled" : ""
                                }`}
                            >
                                <a
                                    class="page-navigator page-link"
                                    href="#"
                                    onClick={tableInstance.nextPage}
                                >
                                    Next<span className="m-1">{`>`}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    );
}
