import { useNavigate } from "react-router-dom";
import {
    useGetPayloadMutation,
    useSignInMutation,
    useVerifySignatureMutation,
} from "../../services/authService";
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "../../constants";
import "./auth.scss";
import Button from "../../components/Button";

import { useContext, useEffect, useState } from "react";
import { Web3AuthContext } from "../../utils/Web3AuthContext";
import { useGetTokenBalancesMutation } from "../../services/indexerService";

// TODO: remove inline styles
export default function SignInPage() {
    const navigate = useNavigate();
    const [signIn] = useSignInMutation();

    const [getPayload] = useGetPayloadMutation();
    const [verifySignature] = useVerifySignatureMutation();

    const { connectWallet, getAccount, signMessage, getTokenBalance, getUserInfo } =
        useContext(Web3AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    const handleSignInSubmit = async () => {
        setIsLoading(true);

        await connectWallet();

        const { pk, pkh } = await getAccount();
        const { email, name } = await getUserInfo();

        const response = await getPayload({
            publicKey: pk,
            publicAddress: pkh,
            additionalInfo: email || name
        });

        const signedPayload = await signMessage(response.data.data.payload);

        const verifyResponse = await verifySignature({
            publicKey: pk,
            publicAddress: pkh,
            signature: signedPayload.prefixSig,
        });

        if (verifyResponse.data.status === "OK") {
            // navigate("/app/onboarding");
            const balances = await getTokenBalance(pkh);

            if (balances.length == 0) {
                navigate("/app/onboarding");
            } else {
                navigate("/app/dashboard");
            }
        } else {
            // show error dialog
            setIsLoading(false);
        }
    };

    return (
        <div className="container-fluid signin-container w-100 h-100 ">
            <div className="row">
                <div className="col-12 col-sm d-flex flex-column justify-content-center align-items-center">
                    <div className="col-xs d-flex flex-column justify-content-center d-md-flex justify-content-md-center min-vh-100 justify-content-lg-center align-items-center align-items-md-start">
                        <div className="m-3 logo">
                            <img
                                src="images/sling-logo.png"
                                width="100px"
                                alt="logo"
                            />
                        </div>
                        <h2 className="m-3 signin-text text-center text-md-start">
                            Sign in to your <span>{<br />}</span>
                            account
                        </h2>
                        {/* <div className="m-3 register-container">
                            <p className="new-to-sling-text">
                                New to Sling?{" "}
                                <span className="register-now">
                                    <a href="#">Register now</a>
                                </span>
                            </p>
                        </div> */}
                        <div className="d-block d-sm-none signin-button text-center">
                            <Button
                                type={"button"}
                                variant="primary"
                                onClick={handleSignInSubmit}
                                isLoading={isLoading}
                            >
                                Sign In!
                            </Button>
                            <p className="new-to-sling-text mt-1">
                                For best experience please use Google Chrome browser or enable pop-ups.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm pe-0 ps-0 d-none d-sm-block">
                    <div className="curve-background p-5 flex-fill">
                        <div className="signin-button">
                            <Button
                                type={"button"}
                                variant="primary"
                                onClick={handleSignInSubmit}
                                isLoading={isLoading}
                            >
                                Sign In
                            </Button>
                            <p className="new-to-sling-text mt-1">
                                By signing in you agree to our <a href="/sling-token-eula.pdf" target="_blank">terms and conditions.</a> <br />
                                For best experience please use Google Chrome browser or enable pop-ups.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
