import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Web3AuthContext } from "../../utils/Web3AuthContext";

export default function SignoutPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { disconnectWallet } = useContext(Web3AuthContext);

    useEffect(() => {
        // // clear redux store
        // dispatch({ type: "auth/logout" });
        // // clear browser storages
        // localStorage.clear();
        // sessionStorage.clear();

        walletLogout();
        //go to signin page
        
    }, [dispatch, navigate]);

    const walletLogout = async () => {
        await disconnectWallet();
        navigate("/signin");
    }

    return null;
}
