import { createApi } from "@reduxjs/toolkit/query/react";
import { publicBaseQuery } from "./baseQueries";
export const authAPI = createApi({
    reducerPath: "authAPI",
    baseQuery: publicBaseQuery,
    endpoints: (build) => ({
        signIn: build.mutation({
            query: ({ username, password }) => ({
                url: `/api/auth/token/`,
                method: "POST",
                body: { username, password },
            }),
        }),
        getPayload: build.mutation({
            query: ({ publicKey, publicAddress, additionalInfo }) => ({
                url: '/api/auth/get-auth-payload/',
                method: "POST",
                body: { 
                    public_key: publicKey,
                    public_address: publicAddress,
                    additional_info: additionalInfo 
                }
            })
        }),
        verifySignature: build.mutation({
            query: ({ publicKey, publicAddress, signature }) => ({
                url: '/api/auth/verify-signature/',
                method: "POST",
                body: {
                    public_key: publicKey,
                    public_address: publicAddress,
                    signature: signature
                }
            })
        })
    }),
});

export const { useSignInMutation, useGetPayloadMutation, useVerifySignatureMutation } = authAPI;
