import React from "react";
import { Link } from "react-router-dom";
import { useRouting } from "../hooks/routesHooks";

function Breadcrumbs() {
    const { breadcumbPath } = useRouting();

    return (
        <>
            {breadcumbPath.map((active, index, { length }) => (
                <span
                    key={index}
                    style={{
                        color: index === 0 ? "grey" : "inherit",
                        fontFamily: "Albert sans",
                    }}
                >
                    {index === 0 ? "" : "/"}
                    {index !== length - 1 ? (
                        <Link to={active.match.pathname}>{active.title}</Link>
                    ) : (
                        <>{active.title}</>
                    )}
                </span>
            ))}
        </>
    );
}

export default Breadcrumbs;
