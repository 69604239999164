import React, { createContext, useEffect } from "react";
import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, IProvider } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

import RPC from "./tezosRPC";
import { useGetTokenBalancesMutation } from "../services/indexerService";

export const web3auth = new Web3Auth({
    clientId: process.env.REACT_APP_WEB3_AUTH_CLIENT_ID,
    chainConfig: {
        chainNamespace: CHAIN_NAMESPACES.OTHER,
        chainId: "1",
        rpcTarget: "https://rpc.tzbeta.net/", 
    },
    web3AuthNetwork: process.env.REACT_APP_WEB3_AUTH_ENV,
});
// const openloginAdapter = new OpenloginAdapter({
//     adapterSettings: {
//         uxMode: "redirect",
//     }
// });
// web3auth.configureAdapter(openloginAdapter);

const Web3AuthContext = createContext(null);
export { Web3AuthContext };

export default ({ children }) => {

    const [getTokenBalancMutation] = useGetTokenBalancesMutation();

    useEffect(() => {
        init();
    }, [])

    async function init(){
        await web3auth.initModal();
        console.log("Web3Auth: Initialized")
    }

    async function connectWallet(){
        await web3auth.connect();
    }

    async function getUserInfo(){
        if(!web3auth.connected){
            // throw "Web3Auth not connected"
            return;
        }

        let userInfo = await web3auth.getUserInfo();

        return {
            email: userInfo.email,
            avatar: userInfo.profileImage,
            name: userInfo.name
        }

    }

    async function getAccount(){
        if(!web3auth.connected){
            throw "Web3Auth not connected"
        }

        const provider = web3auth.provider;
        const rpc = new RPC(provider);
        const keyPair = await rpc.getTezosKeyPair();

        return {
            pk: keyPair?.pk, 
            pkh: keyPair?.pkh
        };
    };

    function isLoggedIn(){
        if(web3auth.connected && web3auth.provider){
            return true;
        }
        
        return false;
    }

    async function getBalance(){
        if(!web3auth.connected){
            throw "Web3Auth not connected"
        }

        const provider = web3auth.provider;
        const rpc = new RPC(provider);
        
        const balance = await rpc.getBalance();

        return balance;
    }

    async function disconnectWallet(){
        await web3auth.logout();
    }

    async function signMessage(message){
        if(!web3auth.connected){
            throw "Web3Auth not connected"
        }

        const provider = web3auth.provider;
        const rpc = new RPC(provider);
        const signedMessaged = await rpc.signMessage(message);

        return signedMessaged;
    }

    async function getTokenBalance(publicAddress){
        try{
            const balances = await getTokenBalancMutation({
                publicAddress: publicAddress,
                contractAddress: process.env.REACT_APP_WERT_SC_ADDRESS
            })

            return balances.data;
        }catch{
            return []
        }
        
    }

    let values = { 
        connectWallet,
        getAccount,
        isLoggedIn,
        getUserInfo,
        getBalance,
        disconnectWallet,
        signMessage,
        getTokenBalance
    }

    return <Web3AuthContext.Provider value={values}>
        {children}
    </Web3AuthContext.Provider>;
}