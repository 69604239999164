import React, { useContext } from "react";
import Button from "./Button";
import { Icon } from "./Icon";
import Card from "./card/CardIcon";
import NFTCheckout from "./NFTCheckout";
import { Web3AuthContext } from "../utils/Web3AuthContext";
import { useNavigate } from "react-router-dom";

export default function Mint() {
    const { isLoggedIn } = useContext(Web3AuthContext);
    const navigate = useNavigate();

    const handleBuyToken = () => {
        navigate("/signin");
    };

    return (
        <div className="col-12 col-lg-8">
            <div className="row d-flex flex-row justify-content-md-center">
                <div className="p-0 col-12 col-md-4 col-lg-4 mb-3 mb-md-0 d-flex flex-column justify-content-center d-md-flex justify-content-md-center flex-md-column align-items-center align-items-md-center">
                    <div className="sling-logo">
                        <img
                            src="/images/sling-logo.jpg"
                            alt="logo"
                            className="rounded-circle"
                            width="184px"
                            height="184px"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-8 d-flex flex-column justify-content-center d-md-flex justify-content-md-center flex-md-column align-items-center align-items-md-start">
                    <h1 className="mt-2 text-center text-md-start">
                        SlingChain EV Token
                    </h1>
                    <p className="para-1 text-center text-md-start">
                        Be a part of the EV revolution with SlingChain battery
                        ownership/rental programme, facilitated by SlingChain
                        blockchain tokens.
                    </p>
                    <div className="pt-2 token-button ">
                        {isLoggedIn() && (
                            <>
                                <h2 className="mb-2">
                                    USD 165.00<sup className="primary">*</sup>
                                </h2>
                                <NFTCheckout />
                            </>
                        )}
                        {!isLoggedIn() && (
                            <>
                                <Button
                                    type={"button"}
                                    variant="primary"
                                    fullWidth={false}
                                    dropend={true}
                                    iconEnd="/icon/arrow-right.svg"
                                    onClick={handleBuyToken}
                                >
                                    Buy tokens
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-5 mb-4 card text-card d-flex flex-column justify-content-evenly text-center text-md-start">
                <p className="p-4 para-1">
                    Sling Mobility, a leader in the electric vehicle (EV)
                    industry, is proud to present a game-changing approach to
                    sustainable investing: our battery-backed blockchain token.
                    Sling Mobility is revolutionizing the EV space by
                    introducing a battery swapping mechanism that allows riders
                    to quickly change their batteries at swapping stations,
                    instead of spending valuable time on charging the battery.
                    <br />
                    <br />
                    SlingChain is a battery ownership model that allows you to
                    be a part of this revolutionary EV ecosystem.
                </p>

                <h2 className="m-4 mt-1 heading-2">
                    How to Participate in Sling Mobility's Token Sale
                </h2>

                <div className="d-flex align-items-start justify-content-start">
                    <ul className="progressbar">
                        <li>
                            <h3 className="mt-3">Register</h3>
                            <p className="para-1">
                                Sign up for the token sale to get started.
                            </p>
                        </li>
                        <li>
                            <h3 className="mt-3">Buy</h3>
                            <p className="para-1">
                                Choose the amount of tokens you wish to
                                purchase.
                            </p>
                        </li>
                        <li>
                            <h3 className="mt-3">Earn Rental</h3>
                            <p className="para-1">
                                The SlingChain tokens are automatically rented
                                out to a pool of riders through Sling Mobility
                                Fleet Management system, and you will earn
                                rental monthly<sup>*</sup>.
                            </p>
                        </li>
                    </ul>
                </div>

                <h2 className="m-4 mb-0 heading-2">
                    Why Sling Mobility's Token Is the Future of Investing
                </h2>
                <p className="m-4 para-1">
                    Harnessing the Power of Blockchain: Our blockchain
                    technology ensures transparent, secure, and immutable
                    transactions. This revolutionary approach eliminates
                    intermediaries, reduces costs, and enables a seamless
                    investment experience for our token holders.
                </p>

                <div className="mx-3 row d-flex flex-wrap">
                    <div className="col-12 col-md-6 d-flex flex-wrap">
                        <Card
                            icon={"/icon/flash.svg"}
                            title={"Harnessing the power of blockchain"}
                            description={
                                "Smart contracts embedded in the system facilitate seamless rental agreements, transparent income distribution, and immutable records of battery utilization. By harnessing the power of blockchain, we're creating a decentralized ecosystem that empowers token holders with a trustless and automated revenue stream."
                            }
                        />
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-wrap">
                        <Card
                            icon={"/icon/battery-full.svg"}
                            title={"Backed by a pool of EV batteries"}
                            description={
                                "SlingChain  tokens are backed by a tangible and high-demand asset – electric vehicle batteries. The intrinsic value of these batteries not only provides stability to the token but also aligns with the global shift towards sustainable energy solutions."
                            }
                        />
                    </div>
                </div>
                <div className="mx-3 row d-flex flex-wrap d-flex flex-wrap">
                    <div className="col-12 col-md-6 d-flex flex-warp">
                        <Card
                            icon={"/icon/flash.svg"}
                            title={"Automated Rental"}
                            description={
                                "The EV Battery Backed Token seamlessly connects with a pool of delivery riders associated with major fleets like Uber, ensuring a constant demand for your battery assets. Without any manual intervention, enjoy the convenience of earning rental income as your batteries actively contribute to the booming gig economy."
                            }
                        />
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-wrap">
                        <Card
                            icon={"/icon/battery-full.svg"}
                            title={"Easy to join"}
                            description={
                                "The process is as simple as buying the EV Battery Backed Token, and from that point onward, you start earning rental income. No complicated procedures or technicalities to navigate. We prioritize accessibility, making it easy everyone to participate in the future of energy-backed digital assets."
                            }
                        />
                    </div>
                </div>
                <h2 className="m-4 mb-0 pt-3 heading-2">
                    The Future of Battery-Powered Mobility
                </h2>
                <p className="m-4 para-1">
                    Sling Mobility is at the forefront of the EV revolution, and
                    we believe that the future is electric. With our
                    battery-backed blockchain token, we invite you to become a
                    part of this electrifying journey. Here's why you should
                    join us:
                </p>
                <div className="mx-4 row">
                    <div className="col-12 col-md-4">
                        <Icon
                            className="mb-2"
                            src={"/icon/tree.svg"}
                            alt="battery"
                            height={32}
                            width={32}
                        />
                        <h4>Promote sustainable mobility</h4>
                        <p className="para-1">
                            Buy purchasing SlingChain token, you contribute to
                            the shift towards a eco-friendly transportation and
                            a cleaner earth. You can make an impact!
                        </p>
                    </div>
                    <div className="col-12 col-md-4">
                        <Icon
                            className="mb-2"
                            src={"/icon/shield-security.svg"}
                            alt="battery"
                            height={32}
                            width={32}
                        />
                        <h4>Secure and Transparent</h4>
                        <p className="para-1">
                            Our blockchain technology ensures the security and
                            transparency of your tokens.
                        </p>
                    </div>
                    <div className="col-12 col-md-4">
                        <Icon
                            className="mb-2"
                            src={"/icon/battery-full.svg"}
                            alt="battery"
                            height={32}
                            width={32}
                        />
                        <h4>Steady monthly returns</h4>
                        <p className="para-1">
                            Enjoy fixed monthly rental income that provide
                            financial stability.
                        </p>
                    </div>
                </div>

                <div className="mx-4 row">
                    <div className="col-12 col-md-4">
                        <Icon
                            className="mb-2"
                            src={"/icon/mobiledata.svg"}
                            alt="battery"
                            height={32}
                            width={32}
                        />
                        <h4>Steady Returns</h4>

                        <p className="para-1">
                            Diversify your portfolio with a unique asset-backed
                            token.
                        </p>
                    </div>
                    <div className="col-12 col-md-4">
                        <Icon
                            className="mb-2"
                            src={"/icon/3square.svg"}
                            alt="battery"
                            height={32}
                            width={32}
                        />
                        <h4>Diverse Portfolio</h4>
                        <div className="para-1">
                            Diversify your investment portfolio with a unique
                            asset-backed token.
                        </div>
                    </div>
                </div>

                <h2 className="m-4 mb-0 pt-3 heading-2">
                    Join the Future of Sustainable Investing
                </h2>
                <div className="m-4 token-button">
                    {isLoggedIn() && <NFTCheckout />}
                    {!isLoggedIn() && (
                        <>
                            <Button
                                type={"button"}
                                variant="primary"
                                fullWidth={false}
                                dropend={true}
                                iconEnd="/icon/arrow-right.svg"
                                onClick={handleBuyToken}
                            >
                                Buy tokens
                            </Button>
                            <a
                                href="/sling-token-launch-guide.pdf"
                                target="_blank"
                                className="btn btn-primary ms-1"
                            >
                                Read guide
                            </a>
                        </>
                    )}
                </div>

                <p className="m-4 para-1">
                    Having trouble? Contact us on
                    <a href="mailto:slingchain@slingmobility.com">
                        {" "}
                        slingchain@slingmobility.com.
                    </a>
                    <br />* Charges apply.
                </p>
            </div>
        </div>
    );
}
