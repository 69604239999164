import AppLayout from "../components/AppLayout";
import SignInPage from "../pages/auth/SignInPage";
import SignoutPage from "../pages/auth/SignoutPage";
import PublicOnly from "../components/routing/PublicOnlyRoute";
import { getRouteObj } from "../utils/routeUtils";
import OnboardingPage from "../pages/onboarding/OnboardingPage";
import MintPage from "../pages/mintpage/MintPage";
import Dashboard from "../pages/dashboard/Dashboard";
import RequireAuth from "../components/routing/PrivateRoute";

export const routesConfig = [
    {
        title: "SLING Mint",
        path: "/",
        element: <AppLayout/>,
        routeId: "HOME_MINT",
        children: [
            {
                title: "Mint",
                path: "", 
                element: <MintPage />,
                routeId: "MINT",
            },
        ]
    },
    {
        
        title: "Home",
        path: "/app",
        element: <AppLayout />,
        routeId: "HOME",
        children: [
        {
            title: "Mint",
            path: "mint", 
            element: <MintPage />,
            routeId: "MINT",
        },
            {
                title:"Onboarding",
                path:"onboarding",
                element: (
                    <RequireAuth>
                        <OnboardingPage/>
                    </RequireAuth>
                ),
                routeId:"HOME_ONBOARDING"
            },
            {
                title:"Dashboard",
                path:"dashboard",
                element: (
                    <RequireAuth>
                        <Dashboard/>
                    </RequireAuth>
                ),
                routeId:"DASHBOARD"
            },
        ]
    },
    {
        title: "SignIn",
        path: "/signin",
        element: (
            <PublicOnly>
                <SignInPage />
            </PublicOnly>
        ),
        routeId: "SIGNIN",
    },
    {
        title: "Signout",
        path: "/signout",
        element: <SignoutPage />,
        routeId: "SIGNOUT",
    },
];

// Returns Route ID Dictionary
export const ROUTES = getRouteObj(routesConfig);
