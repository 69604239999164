import React, { useMemo } from "react";
import {
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Bar,
    ComposedChart,
    Legend,
} from "recharts";


export default function BarCharts({ numTokens }) {

    const data = useMemo(() => {
        return [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(ind => {
            const date = new Date();
            date.setMonth(ind);
        
            const month = date.toLocaleString('en-US', {
                month: 'short',
            });
        
            return {
                month: month,
                Earnings: 5.66*numTokens
            }
        })
    }, [numTokens])

    return (
        <div>
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart width={500} height={300} data={data}>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Earnings" barSize={40} fill="#A8D4FF" />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
}
