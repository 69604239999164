import React from "react";
import "./mintpage.scss";
import Mint from "../../components/Mint";

export default function MintPage() {
    return (
        <div className="container-fluid mint-container w-100 h-100 min-vh-100">
            <div className="row justify-content-center">
                <Mint />
            </div>
        </div>
    );
}
