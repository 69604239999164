import React, { useContext, useEffect, useState } from "react";

import { ROUTES } from "../config/routesConfig";
import { useRouting } from "../hooks/routesHooks";
import "./navbar.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "./Button";
import { Web3AuthContext } from "../utils/Web3AuthContext";

export default function Navbar() {
    const { getRouteLink } = useRouting();
    const { getUserInfo, isLoggedIn } = useContext(Web3AuthContext);

    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({
        email: "",
        name: "",
        avatar: "",
    });

    useEffect(() => {
        initData();
    }, []);

    async function initData() {
        const _userInfo = await getUserInfo();
        setUserInfo(_userInfo);
    }

    return (
        <header className="header">
            <nav className="navbar navbar-expand-lg navbar-light navbar-custom fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="https://slingmobility.com/" >
                        <img
                            src="/images/sling-logo.png"
                            className="ps-3 img-fluid "
                            alt="logo"
                        />
                    </a>

                    <button
                        className="navbar-toggler py-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="navbar-collapse collapse"
                        id="navbarCollapse"
                    >
                        <ul className="nav navbar-nav ms-auto">
                            {isLoggedIn() && (
                                <>
                                    <li className="nav-item my-md-0 my-sm-2 mx-sm-2 dropdown pe-2">
                                        <a
                                            className="dropdown-toggle d-flex flex-row align-items-center"
                                            href=" "
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <div className="initial-avatar">
                                                {userInfo?.avatar ? (
                                                    <img
                                                        className="rounded-circle"
                                                        src={userInfo.avatar}
                                                        width="100%"
                                                        alt="avatar"
                                                    />
                                                ) : (
                                                    <span>
                                                        {userInfo?.name
                                                            ?.split(" ")
                                                            .map((n) => n[0])
                                                            .join("")
                                                            .toUpperCase()}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="ps-2">
                                                <span className="name">
                                                    {userInfo?.name}
                                                </span>
                                                <div className="email">
                                                    {userInfo?.email}
                                                </div>
                                            </div>
                                        </a>

                                        <div className="m-3 dropdown-menu">
                                            <NavLink
                                                className="dropdown-item"
                                                to="/app/dashboard"
                                            >
                                                <p
                                                    className="m-1"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#navbarCollapse"
                                                    aria-expanded="false"
                                                    aria-controls="navbarCollapse"
                                                >
                                                    Dashboard
                                                </p>
                                            </NavLink>
                                            <NavLink
                                                className="dropdown-item"
                                                to="/app/mint"
                                            >
                                                <p
                                                    className="m-1"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#navbarCollapse"
                                                    aria-expanded="false"
                                                    aria-controls="navbarCollapse"
                                                >
                                                    Buy tokens
                                                </p>
                                            </NavLink>
                                            <NavLink
                                                className="dropdown-item"
                                                to={getRouteLink(
                                                    ROUTES.SIGNOUT
                                                )}
                                            >
                                                <p
                                                    className="m-1"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#navbarCollapse"
                                                    aria-expanded="false"
                                                    aria-controls="navbarCollapse"
                                                >
                                                    <Button
                                                        iconStart="/icon/logout.svg"
                                                        variant="secondary"
                                                        className="w-100 my-0"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#navbarCollapse"
                                                        aria-expanded="false"
                                                        aria-controls="navbarCollapse"
                                                    >
                                                        Sign out
                                                    </Button>
                                                </p>
                                            </NavLink>
                                        </div>
                                    </li>
                                </>
                            )}
                            {!isLoggedIn() && (
                                <>
                                    <div className="mx-3 d-flex flex-row align-items-center">
                                        <li className="nav-item">
                                            <NavLink
                                                className="m-auto nav-link"
                                                // to="/signin"
                                                to={getRouteLink(ROUTES.SIGNIN)}
                                            >
                                                Sign in
                                            </NavLink>
                                        </li>
                                    </div>
                                    <li className="nav-item">
                                        <Button
                                            type={"button"}
                                            variant="primary"
                                            fullWidth={false}
                                            onClick={() => navigate(getRouteLink(ROUTES.SIGNIN))}
                                        >
                                            Register now
                                        </Button>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}
