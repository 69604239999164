import React, { useMemo } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

export default function LineCharts({ numTokens }) {

    const data = useMemo(() => {
        return [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((mon, ind) => {
            const date = new Date();
            date.setMonth(mon);
        
            const month = date.toLocaleString('en-US', {
                month: 'short',
            });
        
            return {
                month: month,
                Earnings: 5.66*(ind+1)*numTokens
            }
        })
    }, [numTokens])

    return (
        <div>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="linear"
                        dataKey="Earnings"
                        stroke="#a19ff3"
                        fill="#a19ff3"
                        activeDot={{ r: 8 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
