import React from "react";
import { Icon } from "./Icon";
import "./button.scss";

/**
 * Native button wrapped to enable customization with bootstrap sizes and variants
 * @param {{
 * children: JSX.Element,
 * size: "sm" | "lg",
 * variant: "primary" | "secondary" | "outline-primary" | "outline-secondary",
 * type: "button" | "submit",
 * disabled: boolean,
 * fullWidth: boolean,
 * isLoading: boolean,
 * dropdown: boolean,
 * dropend: boolean,
 * iconStart: string, // New prop for the icon at the start
 * iconEnd: string, // New prop for the icon at the end
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function Button({
    type = "button",
    disabled = false,
    children = "Button",
    size = "",
    variant = "primary",
    fullWidth = true,
    isLoading = false,
    dropdown = false,
    dropend = false,
    icon = "",
    onClick,
    iconStart = "", // Prop for the icon at the start
    iconEnd = "", // Prop for the icon at the end
}) {
    const getButtonClassName = () => {
        const btnClasses = ["", "", "", "", ""];

        if (size && typeof size === "string") {
            btnClasses[0] = `btn-${size}`;
        }
        if (typeof variant === "string") {
            btnClasses[1] = `btn-${variant}`;
        }
        if (fullWidth) {
            btnClasses[2] = "w-100";
        }
        if (dropdown) {
            btnClasses[3] = "dropdown-toggle";
        }
        if (dropend) {
            btnClasses[4] = "dropend";
        }

        return `btn ${btnClasses.join(" ")}`;
    };

    return (
        <button
            type={type}
            className={getButtonClassName()}
            disabled={disabled}
            aria-label="button"
            onClick={onClick}
        >
            {isLoading ? (
                <>
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <span className="ms-2">Loading...</span>
                </>
            ) : (
                <div className="d-flex align-item-center justify-content-center">
                    {iconStart && (
                        <span className="m-2">
                            <Icon
                                src={iconStart}
                                alt="start-icon"
                                height="20"
                                width="20"
                                color="white"
                            />
                        </span>
                    )}
                    {children}
                    {dropdown && (
                        <span className="visually-hidden">
                            <Icon
                                src="icon/down-arrow.svg"
                                alt="dropdown"
                                height="20"
                                width="20"
                                color="white"
                            />
                        </span>
                    )}
                    {dropend && (
                        <span className="m-1 d-flex align-item-center">
                            <Icon
                                src={iconEnd}
                                alt="end-icon"
                                height="20"
                                width="20"
                                color="white"
                            />
                        </span>
                    )}
                </div>
            )}
        </button>
    );
}
