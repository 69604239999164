import { useContext } from "react";
import { ACCESS_TOKEN_NAME } from "../constants";
import { Web3AuthContext } from "../utils/Web3AuthContext";

export const useLoginStatus = () => {
    const { isLoggedIn } = useContext(Web3AuthContext);

    // if (sessionStorage.getItem(ACCESS_TOKEN_NAME)) {
    //     return true;
    // }
    // return false;

    return isLoggedIn();
};
