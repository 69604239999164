import ModalContainer from "./components/modals/ModalContainer";
import "./custom.scss";
import { routesConfig } from "./config/routesConfig";
import RoutesRender from "./components/routing/RoutesRender";

import { web3auth } from "./utils/web3auth";
import { useEffect } from "react";
import Web3AuthProvider from "./utils/Web3AuthContext";

function App() {


    return (
        <Web3AuthProvider>
            <div className="App">
                <ModalContainer />
                <RoutesRender routes={routesConfig} />
            </div>
        </Web3AuthProvider>
    );
}

export default App;
