import React from "react";
import { Icon } from "../Icon";
import "./card.scss";

const Card = ({ title, description, icon }) => {
    return (
        <div className="m-3 card card-icon border-0">
            <div className="m-1 card-body">
                <Icon
                    className="m-2"
                    src={icon}
                    alt={icon}
                    height={32}
                    width={32}
                />
                <h3 className="m-2 card-title">{title}</h3>
                <p className="m-2 card-text">{description}</p>
            </div>
        </div>
    );
};

export default Card;
