import React from "react";
import { Icon } from "../Icon";
import "./card.scss";

const DashboardCard = ({
    text,
    icon,
    amount,
    numberText,
    layoutType,
    change,
    onCLick,
}) => {
    const isPositive = change > 1;
    return (
        <div className="card m-3 border-0 w-100 h-75">
            <div className="card-body">
                <div className="card-title">
                    <div>
                        <span>
                            <h2>
                                <span className="p-3 numberText">
                                    {numberText}
                                </span>
                                {change ? (
                                    <span
                                        className="change-text"
                                        style={{
                                            backgroundColor: isPositive
                                                ? "#CCF4E8"
                                                : "#FFD5D5",
                                            color: isPositive
                                                ? "#01CB8E"
                                                : "#FF2F2F",
                                            paddingTop: "3px",
                                            paddingBottom: "3px",
                                            paddingRight: "7px",
                                            paddingLeft: "7px",
                                        }}
                                    >
                                        <span>{Math.abs(change)}</span>
                                        <span>
                                            {isPositive ? (
                                                <Icon
                                                    src={"/icon/arrow-up.svg"}
                                                />
                                            ) : (
                                                <Icon
                                                    src={"/icon/arrow-down.svg"}
                                                />
                                            )}
                                        </span>
                                    </span>
                                ) : (
                                    ""
                                )}
                            </h2>
                        </span>

                        <h6 className="m-3 card-subtitle mb-2 text-muted">
                            {text}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardCard;
