import React, { useContext } from "react";
import Button from "../../components/Button";
import "./onboarding.scss";
import NFTCheckout from "../../components/NFTCheckout";
import { Web3AuthContext } from "../../utils/Web3AuthContext";
import { useNavigate } from "react-router-dom";

export default function OnboardingPage() {
    const { getBalance } = useContext(Web3AuthContext);
    const navigate = useNavigate();

    const handleNext = async () => {
        navigate("/app/mint");
    };

    return (
        <div
            className="container-fluid onboard-container w-100 h-100 min-vh-100 "
        >
            <div className="mt-5 px-5 row d-flex flex-row">
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center text-center text-md-start">
                    <h1 className="heading-text ">
                        Welcome to the SlingChain Token Dashboard!
                    </h1>
                    <div className="paragraph-container">
                        <p className="paragraph-text">
                            Your Gateway to the EV Revolution
                        </p>
                        <p>
                            We extend our sincere appreciation for choosing SlingChain tokens. By becoming a SlingChain holder, you become a vital participant in the Sling Mobility Ecosystem, where the future of Electric Vehicle Batteries is being shaped.
                        </p>
                        <p>
                        This dashboard is designed with your convenience in mind. Here, you'll have the power to:
                        <ul>
                            <li>Get updates on your token investment</li>
                            <li>Monitor the progress of Electric Vehicle Batteries and track their performance</li>
                            <li>View and withdraw your monthly returns</li>
                        </ul>

                        <p>
                            The SlingChain system is partnered with several blockchain partners and requires you to complete a KYC (Know Your Customer) process that typically takes less than 5 minutes. 
                        </p>
                        <p>
                            However, if you have any questions, please feel free to contact us through slingchain@slingmobility.com
                        </p>




                        </p>
                    </div>
                    <div className="py-4 next-button d-flex justify-content-center d-md-flex justify-content-md-start align-items-center align-items-md-start">
                        <Button
                            type="button"
                            variant="primary"
                            fullWidth={false}
                            dropend={true}
                            iconEnd="/icon/arrow-right.svg"
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    </div>
                </div>
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center text-center text-md-start">
                    <div className="image-fluid mx-2">
                        <img
                            className="sling-image d-block image-fluid"
                            src="/images/sling.jpg"
                            alt="sling"
                            width={"100%"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
