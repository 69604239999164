import React, { useContext, useState } from "react";
import WertWidget from "@wert-io/widget-initializer";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import { v4 as uuidv4 } from "uuid";
import { char2Bytes } from "@taquito/utils";
import { Web3AuthContext } from "../utils/Web3AuthContext";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import "../components/forms/form.scss";
window.Buffer = window.Buffer || require("buffer").Buffer;
window.Buffer.poolSize = 8192;

function NFTCheckout() {
    const { getAccount } = useContext(Web3AuthContext);
    const [wertStatus, setWertStatus] = useState({});

    const [isWidgetOpen, setIsWidgetOpen] = useState(false);

    const navigate = useNavigate();

    const handleWertStatus = (data) => {
        setWertStatus(data);
    };

    const [qty, setQty] = useState(0);

    const handleWidgetClose = (data) => {
        setIsWidgetOpen(false);

        // TODO: Fix the wert issue where variable context is different

        navigate("/app/dashboard");

        // if(wertStatus){
        //     // if a status is given
        //     if(wertStatus?.status === 'pending'){
        //         navigate("/app/dashboard")
        //     }else if(wertStatus?.status === 'failed'){

        //     }else if(wertStatus?.status === 'success'){
        //         navigate("/app/dashboard")
        //     }
        // }else{

        // }
    };

    const handleWidget = async () => {
        setIsWidgetOpen(true);

        const accountAddress = await getAccount();

        const params = {
            entrypoint: "mint",
            value: {
                prim: "Pair",
                args: [
                    {
                        string: accountAddress.pkh,
                    },
                    {
                        int: String(qty),
                    },
                ],
            },
        };

        const bytes = char2Bytes(JSON.stringify(params));
        const privateKey = process.env.REACT_APP_WERT_PRIVATE_KEY;

        const amount = parseFloat(Number(process.env.REACT_APP_WERT_CHAIN_TOKEN_AMOUNT * Number(qty)).toFixed(6));
        // const amount = process.env.REACT_APP_WERT_CHAIN_TOKEN_AMOUNT * Number(qty);

        const signedData = signSmartContractData(
            {
                address: accountAddress.pkh,
                commodity: process.env.REACT_APP_WERT_CHAIN_TOKEN,
                network: process.env.REACT_APP_WERT_CHAIN_ENV,
                commodity_amount: amount,
                sc_address: process.env.REACT_APP_WERT_SC_ADDRESS,
                sc_input_data: bytes,
            },
            privateKey
        );

        const origin =
            process.env.REACT_APP_APP_ENV === "dev"
                ? "https://sandbox.wert.io"
                : "https://widget.wert.io";

        const otherWidgetOptions = {
            window: window,
            partner_id: process.env.REACT_APP_WERT_PARTNER_ID,
            click_id: uuidv4(),
            origin: origin,
            isCryptoHidden: true,

            listeners: {
                close: handleWidgetClose,
                "payment-status": handleWertStatus,
            },
        };

        const nftOptions = {
            extra: {
                item_info: {
                    author: "Sling",
                    image_url:
                        "https://slin-mint-testnet.onrender.com/images/sling-logo.jpg",
                    name: "SlingChain EV Token",
                    seller: "Sling",
                },
            },
        };

        const options = {
            ...signedData,
            ...otherWidgetOptions,
            ...nftOptions,
        };
        const wertWidget = new WertWidget(options);
        wertWidget.mount();
    };

    return (
        <>
            <div className="d-flex flex-column d-flex flex-column justify-content-center d-md-flex justify-content-md-center flex-md-column align-items-center align-items-md-start">
                <div className="form-group mb-0 pb-3">
                    <div className="input-group">
                        <div className="number-input">
                            <button
                                onClick={() => setQty(qty > 0 ? qty - 1 : 0)}
                            />

                            <input
                                className="quantity bg-light"
                                min="0"
                                placeholder="0"
                                name="quantity"
                                value={qty}
                                type="number"
                                onChange={(e) => setQty(e.target.value)}
                            />
                            <button
                                onClick={() => setQty(qty + 1)}
                                className="plus"
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-md-row flex-column justify-content-center align-items-center align-items-md-start">
                    <Button
                        type={"button"}
                        variant="primary"
                        fullWidth={false}
                        dropend={true}
                        iconEnd="/icon/arrow-right.svg"
                        onClick={handleWidget}
                        isLoading={isWidgetOpen}
                    >
                        Buy tokens
                    </Button>
                    <a
                        href="/sling-token-launch-guide.pdf"
                        target="_blank"
                        className="btn btn-primary ms-1 mt-md-0 mt-2"
                    >
                        Read guide
                    </a>
                </div>
            </div>
            {/* <div id="wert-widget" style={{height:"100vh"}}></div> */}
        </>
    );
}

export default NFTCheckout;
