import React, { useContext, useEffect, useMemo, useState } from "react";
import { BasicTable } from "../../components/tables";
import Button from "../../components/Button";
import "../../pages/dashboard/dashboard.scss";
import DashboardCard from "../../components/card/DashboardCard";
import Mint from "../../components/Mint";
import { Web3AuthContext } from "../../utils/Web3AuthContext";
import { useNavigate } from "react-router-dom";
import BarCharts from "../../components/charts/BarChart";
import LineCharts from "../../components/charts/LineChart";

export default function Dashboard() {
    const [activeTab, setActiveTab] = useState(1); // Default active tab is 1
    const [activeChartTab, setActiveChartTab] = useState(1); // Default active tab is 1

    const { getAccount, getTokenBalance } = useContext(Web3AuthContext);
    const navigate = useNavigate();

    const [pageData, setPageData] = useState({
        tokenCount: 0,
    });

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const account = await getAccount();
        const tokenBalance = await getTokenBalance(account.pkh);

        setPageData({
            ...pageData,
            tokenCount: tokenBalance.length,
        });
    };

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    const handleChartTabClick = (tabNumber) => {
        setActiveChartTab(tabNumber);
    };

    const handleBuyToken = () => {
        navigate("/app/mint");
    };

    return (
        <div className="container-fluid dashboard-container w-100 h-100 min-vh-100">
            {/* <div className="m-3 ms-4 breadcrumb">
                <Breadcrumbs />
            </div> */}
            <div className="m-3 ms-4 d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 d-md-flex justify-content-md-between">
                <h1>SlingChain EV Token Dashboard</h1>
                <div className="btn-toolbar mb-2 mb-md-0 p-md-0 p-4">
                    <Button
                        type="button"
                        variant="primary"
                        fullWidth={false}
                        dropend={true}
                        iconEnd="/icon/arrow-right.svg"
                        onClick={handleBuyToken}
                    >
                        Buy more tokens
                    </Button>
                </div>
            </div>
            <div className="m-2 tabbable-panel">
                <ul
                    className="m-2 nav nav-nav-tabs mb-3"
                    id="ex1"
                    role="tablist"
                >
                    {["Statistics", "Payouts", "Information"].map(
                        (tab, index) => (
                            <li
                                key={index}
                                className={`nav-item ${
                                    activeTab === index + 1 ? "active" : ""
                                }`}
                                role="presentation"
                            >
                                <a
                                    className="nav-link"
                                    id={`ex2-tab-${index + 1}`}
                                    data-bs-toggle="tab"
                                    href={`#ex2-tabs-${index + 1}`}
                                    role="tab"
                                    aria-controls={`ex2-tabs-${index + 1}`}
                                    aria-selected={activeTab === index + 1}
                                    onClick={() => handleTabClick(index + 1)}
                                >
                                    {tab}
                                </a>
                            </li>
                        )
                    )}
                </ul>
                <div className="tab-content " id="ex2-content">
                    <div
                        className="tab-pane fade show active"
                        id="ex2-tabs-1"
                        role="tabpanel"
                        aria-labelledby="ex2-tab-1"
                    >
                        <div className="mx-3 row d-flex flex-wrap">
                            <div className="col-12 col-md-3 d-flex flex-wrap">
                                <DashboardCard
                                    numberText={pageData.tokenCount}
                                    text={"Number of tokens owned"}
                                    // change="4"
                                />
                            </div>
                            <div className="col-12 col-md-3 d-flex flex-wrap">
                                <DashboardCard
                                    numberText={"--"}
                                    text={"Total earnings"}
                                    // change="12"
                                />
                            </div>
                            <div className="col-12 col-md-3 d-flex flex-wrap ">
                                <DashboardCard
                                    numberText={"--"}
                                    text={"Last month's earnings"}
                                />
                            </div>
                            <div className="col-12 col-md-3 d-flex flex-wrap">
                                <DashboardCard
                                    numberText={"--"}
                                    text={"ROI"}
                                    // change="0.4"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-2">
                            <h3 className="m-3 ps-2 table-heading">Forecasted Earnings</h3>
                            {/* <div className="filter pe-3 mb-2 mb-md-0">
                                <div
                                    className="m-2 dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                >
                                    <span data-feather="calendar"></span>
                                    This week
                                </div>
                            </div> */}
                        </div>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-2">
                            {/* <h2 className="m-3 ps-2">
                                $00,000 total earnings
                            </h2> */}
                            <div className="pe-3 mb-2 mb-md-0">
                                <div className="m-2">
                                    <ul
                                        className="outer-tabs m-2 nav nav-nav-tabs mb-3"
                                        id="outer-tabs"
                                        role="tablist"
                                    >
                                        {[
                                            "Monthly Earnings",
                                            "Total Earnings",
                                        ].map((tab, index) => (
                                            <li
                                                key={index}
                                                className={`nav-item ${
                                                    activeChartTab === index + 1
                                                        ? "active"
                                                        : ""
                                                }`}
                                                role="presentation"
                                            >
                                                <a
                                                    className="nav-link"
                                                    id={`chart-tabs-${
                                                        index + 1
                                                    }`}
                                                    data-bs-toggle="tab"
                                                    href={`#chart-tabs-${
                                                        index + 1
                                                    }`}
                                                    role="tab"
                                                    aria-controls={`chart-tabs-${
                                                        index + 1
                                                    }`}
                                                    aria-selected={
                                                        activeChartTab ===
                                                        index + 1
                                                    }
                                                    onClick={() =>
                                                        handleChartTabClick(
                                                            index + 1
                                                        )
                                                    }
                                                >
                                                    {/* Dot for Monthly Earnings */}
                                                    {index === 0 && (
                                                        <span className="dot dot-monthly me-2"></span>
                                                    )}
                                                    {/* Dot for Total Earnings */}
                                                    {index === 1 && (
                                                        <span className="dot dot-total me-2"></span>
                                                    )}
                                                    {tab}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="m-3 tab-content" id="outer-tabs-content">
                            <div
                                className={`tab-pane ${
                                    activeChartTab === 1 ? "active" : ""
                                }`}
                                id="chart-tabs-1"
                                role="tabpanel"
                                aria-labelledby="chart-tabs-1"
                            >
                                <BarCharts numTokens={pageData.tokenCount} />
                            </div>
                            <div
                                className={`tab-pane ${
                                    activeChartTab === 2 ? "active" : ""
                                }`}
                                id="chart-tabs-2"
                                role="tabpanel"
                                aria-labelledby="chart-tabs-2"
                            >
                                <LineCharts numTokens={pageData.tokenCount} />
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="ex2-tabs-2"
                        role="tabpanel"
                        aria-labelledby="ex2-tab-2"
                    >
                        <div className="mx-3 row d-flex flex-wrap">
                            <div className="col-12 col-md-4 d-flex flex-wrap">
                                <DashboardCard
                                    numberText={pageData.tokenCount}
                                    text={"Number of tokens owned"}
                                    // change="4"
                                />
                            </div>
                            <div className="col-12 col-md-4 d-flex flex-wrap">
                                <DashboardCard
                                    numberText={"--"}
                                    text={"Total earnings"}
                                    // change="12"
                                />
                            </div>
                            <div className="col-12 col-md-4 d-flex flex-wrap">
                                <DashboardCard
                                    numberText={"--"}
                                    text={"Last month's earnings"}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-2">
                            <h3 className="m-3 ps-2 table-heading">
                                Payments methods
                            </h3>
                            {/* <div className="filter pe-3 mb-2 mb-md-0">
                                <div
                                    className="m-2 dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                >
                                    <span data-feather="calendar"></span>
                                    This week
                                </div>
                            </div> */}
                        </div>
                        <div className="mx-4 mt-1">
                            <BasicTable
                                data={[
                                    {
                                        date: "04/04/2024",
                                        description:
                                            "Initial returns will populate in the next 4 - 6 months",
                                        amount: "--",
                                        status: "Pending",
                                    },
                                ]}
                                columns={[
                                    {
                                        header: "Date",
                                        accessor: "date",
                                    },
                                    {
                                        header: "Description",
                                        accessor: "description",
                                    },
                                    {
                                        header: "Amount",
                                        accessor: "amount",
                                    },
                                    {
                                        header: "Status",
                                        accessor: "status",
                                        sortType: useMemo(() => {
                                            return function (rowA, rowB, id) {
                                                const statusOrder = {
                                                    Completed: 0,
                                                    Failed: 1,
                                                    Pending: 2,
                                                };

                                                const statusA = rowA.values[id];
                                                const statusB = rowB.values[id];

                                                return (
                                                    statusOrder[statusA] -
                                                    statusOrder[statusB]
                                                );
                                            };
                                        }, []),
                                        Cell: ({ value }) => {
                                            let statusColor = "";
                                            let statusBackgroundColor = "";

                                            if (value === "Completed") {
                                                statusColor = "#01CB8E"; // Green text color for Completed
                                                statusBackgroundColor =
                                                    "#CCF4E8 "; // Light green background for Completed
                                            } else if (value === "Failed") {
                                                statusColor = "#FF2F2F"; // Red text color for Failed
                                                statusBackgroundColor =
                                                    "#FFCDD2"; // Light red background for Failed
                                            } else if (value === "Pending") {
                                                statusColor = "#FFB302"; // Orange text color for Pending
                                                statusBackgroundColor =
                                                    "#FFECB3"; // Light orange background for Pending
                                            }

                                            return (
                                                <div>
                                                    <span
                                                        style={{
                                                            color: statusColor,
                                                            backgroundColor:
                                                                statusBackgroundColor,
                                                            padding: "5px",
                                                            fontSize: "14px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {value}
                                                    </span>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                isSortable={true}
                                isPaginated={false}
                                pageSize={3}
                                currentPage={0}
                            />
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="ex2-tabs-3"
                        role="tabpanel"
                        aria-labelledby="ex2-tab-3"
                    >
                        <div className="m-5 d-flex align-items-start">
                            <Mint />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
