import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_APP_ENV === 'dev' ? 'https://api.ghostnet.tzkt.io/v1' : 'https://api.tzkt.io/v1',
});

export const indexerAPI = createApi({
    reducerPath: "indexerAPI",
    baseQuery: baseQuery,
    endpoints: (build) => ({
        getTokenBalances: build.mutation({
            query: ({ publicAddress, contractAddress }) => ({
                url: '/tokens/balances',
                method: "GET",
                params: { 
                    account: publicAddress,
                    "token.standard": "fa2",
                    "token.contract": contractAddress
                },
            }),
        }),
    }),
});

export const { useGetTokenBalancesMutation } = indexerAPI;
