import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, IProvider } from "@web3auth/base";

export const web3auth = new Web3Auth({
    clientId: "BEhnWmUCAcPrrReR6y-wbYMyrJpB1lT-lGf5DlIzn02r9UMffjPq17UVoLvZMkFlvoPd8VdFQf2pik-svjCEr9s",
    chainConfig: {
    chainNamespace: CHAIN_NAMESPACES.OTHER,
    chainId: "1",
    rpcTarget: "https://rpc.tzbeta.net/", // This is the public RPC we have added, please pass on your own endpoint while creating an app  
    },
    web3AuthNetwork: "cyan",
});

export async function connectWallet(){
    await web3auth.connect();
}